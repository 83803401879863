import React from 'react';
import css from './europeanFunds1.module.scss';
import Text from "../../shared/Text/Text";

const EuropeanFunds1 = () => {
    return (
        <div className={css.wrapper}>
            <div className={css.wrapperInner}>
                <Text>
                    PROJEKTY UNIJNE
                    <br/><br/>
                    Informujemy, że DEEPLAI P.S.A. realizuje projekt "Przygotowanie studium wykonalności oraz
                    wniosku o Eurogrant planowanego do realizacji w ramach programu EIC Accelerator" finansowany
                    ze środków Programu Operacyjnego Inteligentny Rozwój 2014-2020 w ramach poddziałania 2.3.6:
                    Granty na Eurogranty.
                    <br/><br/>
                    Opis projektu:
                    <br/><br/>
                    Cele projektu: Celem projektu jest opracowanie studium wykonalności oraz wniosku
                    aplikacyjnego do konkursu EIC Accelerator.
                    <br/><br/>
                    Planowane efekty: Opracowanie i komercjalizacja innowacyjnej technologii TimberFingerprint,
                    pozwalającej na zrewolucjonizowanie rynku pozyskiwania i przetwarzania drewna poprzez
                    skuteczne w 100% uwierzytelnianie pochodzenia drewna, śledzenie zgodności łańcucha dostaw aż
                    do momentu dostarczenia drewna do tartaków, a także zarządzanie zrównoważonym rozwojem w
                    kontekście certyfikatów CO2.
                    <br/><br/>
                    Wartość projektu: 280 060,00PLN
                    <br/><br/>
                    Wkład Funduszy Europejskich: 280 060,00PLN
                </Text>
            </div>
        </div>
    );
};

export default EuropeanFunds1;
